.NavWrapper{
    z-index: 999;
    margin-top: 0vh;
    padding-top: 3vh;
    width: 100%;
    margin-left: 0%;
    padding-right: 10%;
    position: fixed;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    Top:0%;
    padding-bottom: 1%;
    transition: 2s;
}

.NavWrapper_scrolled{
    z-index: 999;
    margin-top: 0vh;
    padding-top: 3vh;
    width: 100%;
    margin-left: 0%;
    padding-right: 10%;
    position: fixed;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    Top:0%;
    padding-bottom: 1%;
    background-color: rgba(255, 255, 255, 0.943);
    box-shadow: 1px 1px 10px 1px rgb(91, 91, 91);
    transition: 3s;
}

.NavWrapper_scrolled_Mobile{
    z-index: 999;
    margin-top: 0vh;
    padding-top: 3vh;
    width: 100%;
    margin-left: 0%;
    padding-right: 10%;
    position: fixed;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    Top:0%;
    padding-bottom: 1%;
    height: 8%;
    background-color: rgba(255, 255, 255, 0.943);
    box-shadow: 1px 1px 10px 1px rgb(91, 91, 91);
    transition: 3s;
   
}


.Nav_HomeButton{
    color: white;
    font-size: 2vw;
    font-weight: 500;  
    margin-top: 0.5%;
}

.Nav_HomeButton_Mobile{
    color: white;
    font-size: 4vw;
    font-weight: 500;  
    margin-top: 0.5%;
    text-align: left;
    Left: 5%;
    position: absolute;
    z-index: 999;
}

.Nav_Trenner{
    color: white;
    font-size: 2vw;
    font-weight: 500;  
    margin-top: 0.5%;
    margin-left: 1%;
    transition: 3s;
}

.Nav_Trenner_scrolled{
    color: rgb(51, 51, 51);
    font-size: 2vw;
    font-weight: 500;  
    margin-top: 0.5%;
    margin-left: 1%;
    transition: 2s;
}

.Nav_NavElement{
    color: white;
    font-size: 2vw;
    font-weight: 100;  
    margin-top: 0.5%;
    margin-left: 2%;
    text-shadow: 1px 2px 1px black;
}

.Nav_NavElement_scrolled{
    color: rgb(35, 35, 35);
    font-size: 2vw;
    font-weight: 100;  
    margin-top: 0.5%;
    margin-left: 2%;
    text-shadow: 1px 2px 1px black;
}

.Nav_NavElement_gone{
    position: absolute;
    margin-left: -5000vw;
}

.Nav_Trenner_gone{
    position: absolute;
    margin-left: -5000vw;
}

.Nav_NavElement:hover{
    cursor: pointer;
    color:rgb(146, 166, 194);
}

.Mobile_Nav_gone{
    position: absolute;
    margin-left: -5000vw;
}

.Mobile_Nav{
    position: fixed;
    Top: 3%;
    border: 2px solid rgb(200, 200, 200);
    Right: 2%;
    width: 8vw;
    font-size: 8vw;
    text-align: center;
    color: white;
    background-color: rgba(255, 255, 255, 0.103);
    z-index: 999;
}

.Mobile_Nav_active{
    position: fixed;
    Top: 3%;
    border: 2px solid rgb(24, 24, 24);
    Right: 2%;
    width: 8vw;
    font-size: 8vw;
    text-align: center;
    color: rgb(0, 0, 0);
    background-color: rgba(255, 255, 255, 0.103);
    z-index: 999;
}

.MobileNavScreen_gone{
    position: absolute;
    margin-left: -5000vw;
}

.MobileNavScreen{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: white;    
    margin-top: -5%;
    z-index: 0;
    display: flex;
    flex-direction: column;
}

.MobileNavScreenTitel{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-top: 5%;
    width: 65%;
    text-align: right;
    font-size: 10vw;
}

.MobileNavScreenElement1{
    border-top: 1px solid rgb(57, 57, 57);
    border-bottom: 1px solid rgb(57, 57, 57);
    width: 60%;
    margin-left: 20%;
    margin-top: 20%;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 7vw;
    height: 7%;
    padding-top: 5%;
    padding-left: 2%
}

.MobileNavScreenElement2{
    border-bottom: 1px solid rgb(57, 57, 57);
    width: 60%;
    margin-left: 20%;
    margin-top: 2%;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 7vw;
    height: 7%;
    padding-top: 5%;
    padding-left: 2%
}