.ProjekteWrapper{
    width: 100vw;
    height: auto;
}

.ProjekteBody{
    height: 100vh;
    width: 78%;
    margin-left: 11%;
    margin-top: 5%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}

.ProjekteBody_mobile{
    height: 100vh;
    width: 95%;
    margin-left: 3%;
    margin-top: 20%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}


.SiteBackground{
    background-image: url('../material/wand.jpg');
    background-repeat: repeat;
    width: 100%;
    height: 150vh;
    border: 1px solid black;
    position: fixed;
    margin-top: -15vh;
    overflow: hidden;
}

.Projekt{
    height: auto;
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    background-color: white;
    box-shadow: 1px 1px 10px 1px rgb(91, 91, 91);
}

.Projekt_mobile{
    height: auto;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    background-color: white;
    box-shadow: 1px 1px 10px 1px rgb(91, 91, 91);
}

.ProjektPic_Aide{
    background-image: url('../material/AiDePic.jpeg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 40%;
}

.ProjektPic_KI{
    background-image: url('../material/SchreibenKI.jpeg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 38.5%;
    Right: 0%;
    position: relative;
}

.ProjektPic_Leseband{
    background-image: url('../material/Leseband.jpeg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 38.5%;
    Right: 0%;
    position: relative;
}

.ProjektPic_kluug{
    background-image: url('../material/kluug.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 38.5%;
    Right: 0%;
    position: relative;
}

.ProjektTextField
{
    width: 60%;
    display: flex;
    flex-direction: column;
    font-size: 1.2vw;
    padding-left: 2vw;
    color: black;
    padding-bottom: 2%;
}

.ProjektTextField_mobile
{
    width: 95%;
    display: flex;
    flex-direction: column;
    font-size: 3.5vw;
    padding-left: 2vw;
    color: black;
    padding-bottom: 2%;
}

.ProjekteTitel{
    font-size: 2vw;
    margin-top: 3%;
    font-weight: 700;
}

.ProjekteTitel_mobile{
    font-size: 4.5vw;
    margin-top: 3%;
    font-weight: 700;
}

.ProjekteUntertitel{
    font-size: 1.4vw;
    font-weight: 400;
    color: rgb(79, 79, 79);
}

.ProjekteUntertitel_mobile{
    font-size: 3.2vw;
    font-weight: 400;
    color: rgb(79, 79, 79);
}

.ProjektText{
    margin-top: 3%;
    padding-right: 3%;
}

.ProjektSektion{
    font-size: 1.8vw;
    font-weight: 700;
    margin-top: 5%;
    margin-bottom: -3%;
}