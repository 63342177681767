.LandingpageWrapper{
    width: 100%;
    height: auto;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}

.LandingpageBanner{
    background-image: url('../material/Hintergrund.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
}

.LandingpageBanner_Christmas{
    background-image: url('../material/Chrismas_Lander.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
}

.LandingpageBanner_Mobile{
    background-image: url('../material/Hintergrund.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 200vw;
    height: 100vh;
    margin-left: -90vw;
}

.LandingPageTitel{
    width: 60%;
    height: 30%;
    position: absolute;
    Left: 8%;
    Top: 21%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.LandingPageTitel_Mobile{
    width: 90%;
    height: 30%;
    position: absolute;
    Left: 4%;
    Top: 6%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    
}

.LandingPageTitel_Row1{
    color: white;
    font-size: 4vw;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-shadow: 1px 2px 1px black;
    text-align: left;
    margin-left: 1%;
}

.LandingPageTitel_Row1_Mobile{
    color: white;
    font-size: 8vw;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-shadow: 1px 2px 1px black;
    text-align: center;
    margin-left: 1%;
}

.LandingPageTitel_Row2{
    color: white;
    font-size: 3.5vw;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-shadow: 1px 2px 1px black;
    text-align: left;
    margin-top: 2%;
    margin-left: 1%;
}

.LandingPageTitel_Row2_Mobile{
    color: white;
    font-size: 6.5vw;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-shadow: 1px 2px 1px black;
    text-align: center;
    margin-top: 6%;
    margin-left: 1%;
}

.LandingPageNewsLine{
    border-right: 2px solid rgb(180, 180, 180);
    width: 20%;
    height: 50%;
    position: absolute;
    Right: 3%;
    Top: 20%;
    display: flex;
    flex-direction: column;
}

.LandingPageNewsLine_Mobile{
    border-top: 2px solid rgb(180, 180, 180);
    width: 80%;
    height: 50%;
    position: absolute;
    Left: 10%;
    Top: 86%;
    display: flex;
    flex-direction: row;
}

.LandingPageNewsElement{
    height: auto;
    text-align: right;
    margin-right: 5%;
    margin-top: 10%;
}

.LandingPageNewsElement_Mobile{
    height: auto;
    text-align: center;
    margin-right: 5%;
    margin-top: 5%;
}

.NewsElementDate{
    font-size: 0.8vw;
    color: rgb(178, 178, 178);
}

.NewsElementDate_Mobile{
    font-size: 2.5vw;
    color: rgb(178, 178, 178);
}

.NewsElementTitel{
    color: white;
    font-size: 1.5vw;
    font-weight: 500;
}

.NewsElementTitel_Mobile{
    color: white;
    font-size: 3.5vw;
    font-weight: 500;
}