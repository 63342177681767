.SiteBackground{
    background-image: url('../material/wand.jpg');
    background-repeat: repeat;
    width: 100%;
    height: 150vh;
    border: 1px solid black;
    position: fixed;
    margin-top: -15vh;
    overflow: hidden;
}

.ArticleWrapper{
    width: 100%;
    height: auto;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}

.ArticleBody{
    background-color: aliceblue;
    width: 80%;
    height: auto;
    position: relative;
    margin-top: 9%;
    margin-left: 10%;
    box-shadow: 1px 1px 10px 1px rgb(91, 91, 91);
    display: flex;
    flex-direction: column;
    padding-bottom: 3%;
}

.ArticleBody_Mobile{
    background-color: aliceblue;
    width: 90%;
    height: auto;
    position: relative;
    margin-top: 20%;
    margin-left: 5%;
    box-shadow: 1px 1px 10px 1px rgb(91, 91, 91);
    display: flex;
    flex-direction: column;
    padding-bottom: 3%;
}

.ArticleHeader{
    width: 100%;
    height: 25%
}

.ArticleHeaderDatum{
    margin-top: 3.5%;
    margin-left: 10%;
    font-size: 110%;
    color: rgb(91, 91, 91);
}

.ArticleHeaderTitle{
    margin-top: 0.1%;
    margin-left: 10%;
    font-size: 180%;
    color: rgb(0, 0, 0);
    font-weight: 600;
    width: 80%;
}

.ArticleHeaderSubitel{
    margin-top: 0.1%;
    margin-left: 10%;
    font-size: 120%;
    color: rgb(0, 0, 0);
    font-weight: 600;
    width: 80%;
}

.ArticleTextBody{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: 10%;
    margin-top: 1%;
}

.ArticleColumn{
    margin-top: 1%;
    margin-bottom: 1%;
    font-size: 110%;
    text-align: justify;
}

.ArticleColumn_PicRight{
    display: flex;
    flex-direction: row;
    margin-top: 1%;
    margin-bottom: 1%;
}

.ArticleColumn_PicLeft{
    display: flex;
    flex-direction: row;
    margin-top: 1%;
    margin-bottom: 1%;
}

.ArticleColumn_PicLeft_Mobile{
    display: flex;
    flex-direction: column;
    margin-top: 1%;
    margin-bottom: 1%;
}

.ArticleColumn_PicRight_Mobile{
    display: flex;
    flex-direction: column;
    margin-top: 1%;
    margin-bottom: 1%;
}

.PicRightText{
    width: 60%;
    font-size: 110%;
    padding-right: 1%;
    text-align: justify;
}

.PicRightFrame{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    justify-content: center;
}

.PicRight_Subtitle{
    text-align: center;
    color: rgb(91, 91, 91);
    font-size: 80%;
}

.PicLeftText{
    width: 60%;
    font-size: 110%;
    padding-right: 0%;
    padding-left: 2%;
    text-align: justify;
}

.PicLeftText_Mobile{
    width: 100%;
    font-size: 110%;
    padding-right: 0%;
    padding-left: 2%;
    text-align: justify;
    hyphens: auto;
}

.PicRightText_Mobile{
    width: 100%;
    font-size: 110%;
    padding-right: 0%;
    padding-left: 2%;
    text-align: justify;
    hyphens: auto;
}

.PicLeftFrame{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.PicLeftFrame_Mobile{
    width: 100%;
    display: flex;
    height: 35vh;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-bottom: 3vh;
}

.PicRightFrame_Mobile{
    width: 100%;
    display: flex;
    height: 35vh;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-bottom: 3vh;
}

.PicLeft_Subtitle{
    text-align: center;
    color: rgb(91, 91, 91);
    font-size: 80%;
}






.PicRight_PICTURE{
    background-image: url('../material/AiDePic.jpeg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
}

.Pic_Trialog1{
    background-image: url('../article_pics/SinaFoto.jpeg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    align-self: center;
    justify-self: center;
}

.Pic_Trialog2{
    background-image: url('../article_pics/Wrana.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    align-self: center;
    justify-self: center;
}

.Pic_Trialog3{
    background-image: url('../article_pics/Dorfler.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    align-self: center;
    justify-self: center;
}

.Pic_Trialog4{
    background-image: url('../article_pics/Gerrit.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    align-self: center;
    justify-self: center;
}

.Pic_DubeGrafik{
    background-image: url('../article_pics/DubeGrafik.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    align-self: center;
    justify-self: center;
}

.Pic_GlaabGrafik{
    background-image: url('../article_pics/GlaabGrafik.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    align-self: center;
    justify-self: center;
}


