.PersonWrapper{
    width: 100vw;
    height: auto;  
}

.SiteBackground{
    background-image: url('../material/wand.jpg');
    background-repeat: repeat;
    width: 100%;
    height: 150vh;
    border: 1px solid black;
    position: fixed;
    margin-top: -15vh;
    overflow: hidden;
}

.PersonBody{
    height: 100vh;
    width: 78%;
    margin-left: 11%;
    margin-top: 5%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}

.PersonBody_Mobile{
   height: 100vh;
   width: 95%;
   margin-left: 2%;
   margin-top: 5%;
   height: auto;
   display: flex;
   flex-direction: column;
   position: relative;
}

.PersonIdCard{
   background-color: rgb(231, 231, 231);
    width: 90%;
    margin-left: 5%;
    margin-top: 10%;
    height: auto;
    display: flex;
    flex-direction: row;
    box-shadow: 1px 1px 10px 1px black;
}

.PersonIdCard_Mobile{
    background-color: rgb(231, 231, 231);
    width: 90%;
    margin-left: 5%;
    margin-top: 20%;
    height: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 1px 10px 1px black;
}

.PersonIdCardPic{
    background-image: url('../material/Profilbild.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 25vw;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 2%;
}

.PersonIdCardPic2{
   background-image: url('../material/Profilbild2.jpg');
   background-repeat: no-repeat;
   background-size: contain;
   width: 25vw;
   margin-top: 2%;
   margin-bottom: 2%;
   margin-left: 2%;
}

.PersonIdCardPic_Mobile{
   background-image: url('../material/Profilbild.jpg');
   background-repeat: no-repeat;
   background-size: contain;
   width: auto;
   height: 40vh;
   margin-top: 2%;
   margin-bottom: 2%;
   margin-left: 7%;
}

.PersonIdCardPic2_Mobile{
   background-image: url('../material/Profilbild2.jpg');
   background-repeat: no-repeat;
   background-size: contain;
   width: auto;
   height: 38vh;
   margin-top: 2%;
   margin-bottom: 2%;
   margin-left: 4%;
}

.PersonIdCardText{
    
    width: 53%;
    height: 90%;
    margin-top: 2%;
    margin-left: 0%;
    margin-bottom: 5%;
}

.PersonIdCardText_Mobile{
    
   width: 85%;
   height: 90%;
   margin-top: 2%;
   margin-left: 7%;
   margin-bottom: 5%;
}

.PersonIdCardTextHD{
    font-size: 3vw;
    font-weight: 500;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    margin-bottom: 10%;
    margin-top: 1%;
}

.PersonIdCardTextHD_Mobile{
   font-size: 8vw;
   font-weight: 600;
   font-family:Verdana, Geneva, Tahoma, sans-serif;
   margin-bottom: 10%;
   margin-top: 1%;
   text-align: center;
}

.PersonIdCardTextLine{
    font-size: 1.5vw;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-left: 5%;
}

.PersonIdCardTextLine_Mobile{
   font-size: 4.5vw;
   font-family:Verdana, Geneva, Tahoma, sans-serif;
   margin-top: 5%;
   margin-bottom: 3%;
   margin-left: 0%;
   text-align: center;
}
   
    
.JobsCard{
   background-color: rgb(231, 231, 231);
    width: 90%;
    margin-left: 5%;
    margin-top: 5%;
    height: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 1px 10px 1px black;
    text-align: center;
 }

 .JobsCardTitel{
    font-size: 2vw;
    font-weight: 500;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    margin-bottom: 2%;
    margin-top: 2%;
    margin-left: 2%;
 }

 .JobsCardTitel_Mobile{
   font-size: 6vw;
   font-weight: 600;
   font-family:Verdana, Geneva, Tahoma, sans-serif;
   margin-bottom: 2%;
   margin-top: 2%;
   margin-left: 2%;
}

 .JobsCardJobArea{
    margin-bottom: 1%;
    margin-left: 2%;
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
 }

 .JobsCardRow{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
 }

 .JobsCordRowYear{
    width: 20%;
    text-align: center;
    font-size: 1.4vw;
    font-weight: 500;
 }

 .JobsCordRowYear_Mobile{
   width: 40%;
   text-align: center;
   font-size: 4.7vw;
   font-weight: 500;
}

 .JobsCordRowPlace{
    width: 30%;
    text-align: center;
    font-size: 1.4vw;
    font-weight: 500;
 }

 .JobsCordRowPlace_Mobile{
   position: absolute;
   margin-left: -5000vw;
   font-size: 0.0vw;
}

 .JobsCordRowJob{
    width: 50%;
    text-align: center;
    font-size: 1.4vw;
    font-weight: 400;
 }

 .JobsCordRowJob_Mobile{
   width: 60%;
   text-align: left;
   font-size: 4.4vw;
   font-weight: 400;
}

 .JobsCardTrenner{
    border: 1px solid black;
    margin-top: 2%;
    margin-bottom: 2%;
 }

 .JobsCardTrenner_Blank{
    margin-top: 2%;
    margin-bottom: 2%;
 }

 .SchoolYear{
   
    width: 25%;
    text-align: center;
    font-size: 1.4vw;
    font-weight: 500;
 }

 .SchoolYear_Mobile{
   
   width: 40%;
   text-align: center;
   font-size: 4.8vw;
   font-weight: 500;
   color:rgb(56, 56, 56);
}

 .SchoolPlace{
    width: 30%;
    text-align: center;
    font-size: 1.4vw;
    font-weight: 500;
 }

 .SchoolPlace_Mobile{
   width: 0%;
   text-align: center;
   font-size: 0vw;
   font-weight: 500;
}

 .SchoolGoal{
    width: 50%;
    text-align: center;
    font-size: 1.4vw;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    margin-left: 5%;
 }

 .SchoolGoalTitel{
    text-align: left;
 }

 .SchoolGoalTitel_Mobile{
   text-align: left;
   font-size: 5.5vw;
   font-weight: 600;
}

 .SchoolNote{
    width: 100%;
    text-align: left;
    font-size: 1.1vw;
    font-weight: 400;
 }

 .SchoolNote_Mobile{
   width: 100%;
   text-align: left;
   font-size: 3.5vw;
   font-weight: 400;
}