.FooterWrapper{
    width: 80%;
    height: 10vh;
    position: relative;
    bottom: -10%;
    left: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.FooterText1{
    color: rgb(0, 0, 0);
    font-size: 1.5vw;
    font-weight: 100;  
    margin-top: 1%;
    margin-left: 2%;
}

.FooterText2{
    color: rgb(0, 0, 0);
    font-size: 1.5vw;
    font-weight: 500;  
    margin-top: 1%;
    margin-left: 2%;
}

.FooterText1_Mobile{
    color: rgb(0, 0, 0);
    font-size: 3.5vw;
    font-weight: 100;  
    margin-top: 3%;
    margin-left: 2%;
}

.FooterText2_Mobile{
    color: rgb(0, 0, 0);
    font-size: 3.5vw;
    font-weight: 500;  
    margin-top: 3%;
    margin-left: 2%;
}