.ImpressumWrapper{
    width: 100vw;
    height: 101vh;  
}

.SiteBackground{
    background-image: url('../material/wand.jpg');
    background-repeat: repeat;
    width: 100%;
    height: 150vh;
    border: 1px solid black;
    position: fixed;
    margin-top: -15vh;
    overflow: hidden;
}

.ImpressumBody{
    height: 100vh;
    width: 78%;
    margin-left: 11%;
    margin-top: 5%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}

.ImpressumBody_Mobile{
    height: 100vh;
    width: 95%;
    margin-left: 3%;
    margin-top: 5%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}

.ImpressumField{
    
    width: 50vw;
    margin-left: 15vw;
    margin-top: 20vh;
    height: auto;
}

.ImpressumField_Mobile{
    width: 50vw;
    margin-left: 15vw;
    margin-top: 20vh;
    height: auto;
}

.ImpressumField_Titel{
    font-size: 1.4vw;
    font-weight: 500;
    margin-left: 2vw;
    margin-bottom: 2%;
}

.ImpressumField_Titel_Mobile{
    font-size: 4.5vw;
    font-weight: 500;
    margin-left: 2vw;
    margin-bottom: 2%;
}

.Impressum_TextRow{
    font-size: 1.3vw;
    font-weight: 400;
    margin-left: 2vw;
    margin-top: 1%;
}

.Impressum_TextRow_Mobile{
    font-size: 3.5vw;
    font-weight: 400;
    margin-left: 2vw;
    margin-top: 1%;
}

.Impressum_TextRow_Blank{
    font-size: 1.3vw;
    font-weight: 400;
    margin-left: 2vw;
    margin-top: 1%;
    height:2vh;
}

.Impressum_TextRow_Blank_Mobile{
    font-size: 1.3vw;
    font-weight: 400;
    margin-left: 2vw;
    margin-top: 1%;
    height:2vh;
}