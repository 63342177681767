.NewsWrapper{
    width: 100vw;
    height: auto;
}

.SiteBackground{
    background-image: url('../material/wand.jpg');
    background-repeat: repeat;
    width: 100%;
    height: 150vh;
    border: 1px solid black;
    position: fixed;
    margin-top: -15vh;
    overflow: hidden;
}

.NewsBody{
    width: 78%;
    margin-left: 11%;
    margin-top: 5%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}

.NewsBody_Mobile{
    width: 91%;
    margin-left: 3%;
    margin-top: 20%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}

.NewsBodyRow{
    margin-top: 5%;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
}

.NewsBodyRow_Mobile{
    margin-top: 5%;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}


.NewsPublications{
    box-shadow: 1px 1px 10px 1px rgb(84, 84, 84);
    width: 65%;
    height: auto;
    background-color: rgba(255, 255, 255, 0.822);
}

.NewsPublications_Mobile{
    box-shadow: 1px 1px 10px 1px rgb(84, 84, 84);
    width: 100%;
    height: auto;
    background-color: rgba(255, 255, 255, 0.822);
}

.NewsDates{
    box-shadow: 1px 1px 10px 1px rgb(84, 84, 84);
    width: 35%;
    margin-left: 2%;
    background-color: rgba(255, 255, 255, 0.822);
    display: flex;
    flex-direction: column;
    height: auto;
}

.NewsDates_Mobile{
    box-shadow: 1px 1px 10px 1px rgb(84, 84, 84);
    width: 100%;
    margin-left: 0%;
    margin-top: 5%;
    background-color: rgba(241, 243, 113, 0.822);
    display: flex;
    flex-direction: column;
    height: auto;
}

.NewsDatesTitel{
    width: 100%;
    text-align: center;
    font-size: 1.8vw;
    font-weight: 500;
    margin-top: 1vh;
}

.NewsDatesTitel_Mobile{
    width: 100%;
    text-align: center;
    font-size: 5.5vw;
    font-weight: 500;
    margin-top: 1vh;
}

.NewsPublicationsTitel
{
    width: 100%;
    text-align: center;
    font-size: 1.8vw;
    font-weight: 500;
    margin-top: 1vh;
}

.NewsPublicationsTitel_Mobile
{
    width: 100%;
    text-align: center;
    font-size: 5.5vw;
    font-weight: 500;
    margin-top: 1vh;
}

.NewsDatesRow{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    margin-top: 2%;
    margin-bottom: 1%;
}

.NewsDatesDate
{
    
    width: 30%;
    text-align: center;
    font-size: 1.3vw;
    margin-left: 1%;
}

.NewsDatesDate_Mobile
{
    width: 30%;
    text-align: center;
    font-size: 3.5vw;
    margin-left: 0%;
    font-weight: 600;
}

.NewsDateText
{
    
    width: 65%;
    margin-left: 5%;
    font-size: 1.3vw;
}

.NewsDateText_Mobile
{
    
    width: 65%;
    margin-left: 5%;
    font-size: 3.5vw;
}

.NewsDateTextH1
{
    font-weight: 600;
}


.Link2{
    color: rgb(112, 111, 111);
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.NewsPubRow{
    margin-top: 3vh;
    margin-bottom: 3vh;
    height: auto;
    width: 90%;
    margin-left: 5%;
    font-size: 1.3vw;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.NewsPubRow_Mobile{
    margin-top: 3vh;
    margin-bottom: 3vh;
    height: auto;
    width: 90%;
    margin-left: 5%;
    font-size: 3.5vw;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.NewsFeed{
    box-shadow: 1px 1px 10px 1px rgb(84, 84, 84);
    width: 100%;
    height: auto;
    background-color: rgba(255, 255, 255, 0.822);
    display: flex;
    flex-direction: column;
}


.Feed1{
    width: 96%;
    height: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 2%;
    box-shadow: 1px 1px 10px 1px rgb(138, 138, 138);
    display: flex;
    flex-direction: row;
    transition: 2s;
    padding-bottom: 1%;
}

.Feed1_Mobile{
    width: 96%;
    height: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 2%;
    box-shadow: 1px 1px 10px 1px rgb(138, 138, 138);
    display: flex;
    flex-direction: column;
    transition: 2s;
    padding-bottom: 1%;
}

.Feed1:hover{
    width: 96%;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 2%;
    box-shadow: 1px 1px 10px 1px rgb(0, 0, 0);
    display: flex;
    flex-direction: row;
    transition: 2s;
    cursor: pointer;
}

.Feed2{
    width: 96%;
    height: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 2%;
    box-shadow: 1px 1px 10px 1px rgb(138, 138, 138);
    display: flex;
    flex-direction: row;
    transition: 2s;
    padding-bottom: 1%;
}

.Feed2_Mobile{
    width: 96%;
    height: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 2%;
    box-shadow: 1px 1px 10px 1px rgb(138, 138, 138);
    display: flex;
    flex-direction: column;
    transition: 2s;
    padding-bottom: 1%;
}

.FeedPic_NewYear{
    background-image: url('../material/NewYear.jpeg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: 0%;
    position: relative;
    margin-top: 1%;
    margin-left: 1%;
}

.Feed_TextArea{
    
    width: 55%;
    display: flex;
    flex-direction: column;
    font-size: 1.2vw;
    text-align: justify;
    padding-right: 1%;
}

.Feed_TextArea_Mobile{
    
    width: 95;
    display: flex;
    flex-direction: column;
    font-size: 3.5vw;
    margin-left: 2%;
}

.Feed2_TextArea{
    
    width: 55%;
    display: flex;
    flex-direction: column;
    font-size: 1.2vw;
    margin-left: 2%;
    text-align: justify;
}

.Feed_Titel{
    font-size: 1.6vw;
    font-weight: 500;
    margin-bottom: 1%;
}

.Feed_Titel_Mobile{
    font-size: 5.6vw;
    font-weight: 500;
    margin-bottom: 1%;
}

.Feed2_Titel{
    font-size: 1.6vw;
    font-weight: 500;
    margin-bottom: 1%;
    margin-left: 5%;
}

.Feed_Date{
    margin-top: 2%;
    font-size: 0.9vw;
    margin-bottom: -1%;
    font-weight: 600;
    color: rgb(79, 79, 79);
    text-align: left;
    width: 100%;
}

.Feed_Date_Mobile{
    margin-top: 2%;
    font-size: 3.5vw;
    margin-bottom: -1%;
    font-weight: 600;
    color: rgb(79, 79, 79);
    text-align: left;
    width: 100%;
}

.Feed2_Date{
    margin-top: 2%;
    font-size: 0.9vw;
    margin-bottom: -1%;
    font-weight: 600;
    color: rgb(79, 79, 79);
    text-align: left;
    width: 100%;
    margin-left: 5%;
}

.Feed_Text{
    margin-top: 1%;
}

.Feed2_Text{
    margin-top: 1%;
    margin-left: 5%;
}

.FeedPic_WritingAI{
    background-image: url('../material/Schreiben_mit_KI_Lehrerin.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: 0%;
    position: relative;
    margin-top: 1%;
    margin-left: 1%;
}

.FeedPic_Trialog{
    background-image: url('../article_pics/Gerrit.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: 0%;
    position: relative;
    margin-top: 1%;
    margin-left: 1%;
}

.FeedPic_kluug{
    background-image: url('../article_pics/kluugBild.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: 0%;
    position: relative;
    margin-top: 1%;
    margin-left: 1%;
}

.FeedPic_8AGLesen{
    background-image: url('../article_pics/UniKoblenz.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: 0%;
    position: relative;
    margin-top: 1%;
}

.FeedPic_Hingeschaut{
    background-image: url('../article_pics/Wuwu.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: -20%;
    position: relative;
    margin-top: 1%;
}

.FeedPic_Chants{
    background-image: url('../article_pics/Rundisc_Chans_Of_Sennaar_Cover.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: -20%;
    position: relative;
    margin-top: 1%;
}

.FeedPic_WorkshopFeedback{
    background-image: url('../article_pics/Poster_SchreibenmitKI_Insta.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: -5%;
    position: relative;
    margin-top: 1%;
}

.FeedPic_PostDocAkademie{
    background-image: url('../article_pics/FSU.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: 0%;
    position: relative;
    margin-top: 1%;
    margin-left: 1%;
}

.FeedPic_NEST{
    background-image: url('../article_pics/NEST1.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: 0%;
    position: relative;
    margin-top: 1%;
    margin-left: 1%;
}

.FeedPic_RISTAL{
    background-image: url('../article_pics/RISTAL.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: -5%;
    position: relative;
    margin-top: 1%;
}

.FeedPic_Poster_SDD_2024{
    background-image: url('../article_pics/Poster_SDD_2024.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: -5%;
    position: relative;
    margin-top: 1%;
}

.FeedPic_SDD_Plenum{
    background-image: url('../article_pics/SDD_2024_Plenum.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: -1%;
    position: relative;
    margin-top: 1%;
}

.FeedPic_eTeach_Jahrestagung{
    background-image: url('../article_pics/NewsPic_eTeach.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: -0%;
    position: relative;
    margin-top: 10%;
}

.FeedPic_Zukunfstforum_Karlsruhe{
    background-image: url('../article_pics/Karlsruhe_Zukunftsforum_1.1.1.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: -1%;
    position: relative;
    margin-top: 3%;
}

.FeedPic_Entfremdung{
    background-image: url('../article_pics/Schulentfremdung.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: -1%;
    position: relative;
    margin-top: 3%;
}

.FeedPic_JDLTE1{
    background-image: url('../article_pics/JDLTE_4_3.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: -1%;
    position: relative;
    margin-top: 3%;
}

.FeedPic_KI_DerDeutschunterricht{
    background-image: url('../article_pics/KI_DerDeutschunterricht.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50.5%;
    Right: -10%;
    position: relative;
    margin-top: 3%;
}